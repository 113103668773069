import { createRouter, createMemoryHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: '/home'
  }, {
    path: '/home',
    name: 'home',
    component: () => import('../views/homePage.vue')
  }
]

const router = createRouter({
  history: createMemoryHistory(),
  routes
})

export default router;
